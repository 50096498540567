var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"background-color":"white","max-width":"100"}},[_c('div',{staticClass:"col-12 col-lg-12"}),_c('div',{staticStyle:{"background-color":"white","margin-right":"-18px","margin-left":"-18px","max-width":"95"},attrs:{"id":"shops"}},[_c('router-link',{attrs:{"to":{ name: 'treats-plus' }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/img/treatsplus.jpg'),expression:"'/img/treatsplus.jpg'"}],attrs:{"alt":"Treats Plus"}})])],1),_c('div',{staticClass:"col-12 col-lg-12"},[_c('div',{staticClass:"row home-shop-header justify-content-between",staticStyle:{"background-color":"#ffe8df"}},[_vm._m(0),_c('div',{staticClass:"pr-2"},[_c('p',{staticClass:"go-to-store-button"},[_c('router-link',{staticStyle:{"text-wrap":"nowrap","font-weight":"bold"},attrs:{"to":{
                name: 'category_products',
                params: { slug: 'treats-gifts-and-alcohol' },
              }}},[_vm._v("View More >")])],1)])]),(_vm.loading)?_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"type":"grow","variant":"warning","rounded":"sm"}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"ps-shopping-product"},[_c('div',{staticClass:"row"},_vm._l((_vm.treatsCenterProducts),function(product){return _c('div',{key:product.id,staticClass:"col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6"},[_c('Product-card',{attrs:{"product":product}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"row home-shop-header justify-content-between",staticStyle:{"background-color":"#ffe8df"}},[_vm._m(1),_c('div',{staticClass:"pr-2"},[_c('p',{staticClass:"go-to-store-button"},[_c('router-link',{staticStyle:{"text-wrap":"nowrap","font-weight":"bold"},attrs:{"to":{
                name: 'category_products',
                params: { slug: 'groceries-and-fresh-food' },
              }}},[_vm._v("View More >")])],1)])]),(_vm.loading)?_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"type":"grow","variant":"warning","rounded":"sm"}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"ps-shopping-product"},[_c('div',{staticClass:"row"},_vm._l((_vm.shoppingCenterProducts),function(product){return _c('div',{key:product.id,staticClass:"col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6"},[_c('Product-card',{attrs:{"product":product}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"row home-shop-header justify-content-between",staticStyle:{"background-color":"#ffe8df"}},[_vm._m(2),_c('div',{staticClass:"pr-2"},[_c('p',{staticClass:"go-to-store-button"},[_c('router-link',{staticStyle:{"text-wrap":"nowrap","font-weight":"bold"},attrs:{"to":{
                name: 'category_products',
                params: { slug: 'building-materials-hardware' },
              }}},[_vm._v("View More >")])],1)])]),(_vm.loading)?_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"type":"grow","variant":"warning","rounded":"sm"}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"ps-shopping-product"},[_c('div',{staticClass:"row"},_vm._l((_vm.tileToRoofCenterProducts),function(product){return _c('div',{key:product.id,staticClass:"col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6"},[_c('Product-card',{attrs:{"product":product}})],1)}),0)]):_vm._e(),_c('div',{staticStyle:{"background-color":"white","margin-right":"-20px","margin-left":"-20px","margin-bottom":"-1px","margin-top":"-1px"}},[_c('Categories')],1)],1),_c('div',{staticClass:"col-12 col-lg-12",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"row home-shop-header justify-content-between",staticStyle:{"background-color":"#ffe8df"}},[_vm._m(3),_c('div',{staticClass:"pr-2"},[_c('p',{staticClass:"go-to-store-button"},[_c('router-link',{staticStyle:{"text-wrap":"nowrap","font-weight":"bold"},attrs:{"to":{
                name: 'category_products',
                params: { slug: 'informal-marketplace-center' },
              }}},[_vm._v("View More >")])],1)])]),(_vm.loading)?_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"type":"grow","variant":"warning","rounded":"sm"}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"ps-shopping-product"},[_c('div',{staticClass:"row"},_vm._l((_vm.informalMarketCenterProducts),function(product){return _c('div',{key:product.id,staticClass:"col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6"},[_c('Product-card',{attrs:{"product":product}})],1)}),0)]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"70%"}},[_c('h3',{staticClass:"text-left mb-1 pl-2"},[_vm._v("SendTreats Lov’ed Ones Center")]),_c('p',{staticClass:"text-left m-0 pl-2",staticStyle:{"color":"black","font-size":"1.2rem"}},[_vm._v(" Spoil them and Treat them with Exceptional Liquors! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"70%"}},[_c('h3',{staticClass:"text-left mb-1 pl-2"},[_vm._v("Essentials - Groceries Center")]),_c('p',{staticClass:"text-left m-0 pl-2",staticStyle:{"color":"black","font-size":"1.2rem"}},[_vm._v(" Lowest prices of lov'ed ones essentials ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"70%"}},[_c('h3',{staticClass:"text-left mb-1 pl-2"},[_vm._v("Tiles to Roof Building Center")]),_c('p',{staticClass:"text-left m-0 pl-2",staticStyle:{"color":"black","font-size":"1.2rem"}},[_vm._v(" Home Improvements & Building Supplies Depot ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"70%"}},[_c('h3',{staticClass:"text-left mb-1 pl-2"},[_vm._v("Informal Traders Center")]),_c('p',{staticClass:"text-left m-0 pl-2",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" Zim's informal Sector Online Open Marketplace ")])])
}]

export { render, staticRenderFns }